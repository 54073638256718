import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import './style.css';

function MainTopNavbar(props) {
    const { brand, logo } = props;

    return (
        <Navbar expand="lg" bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={logo}
                        width="30px"
                        height="30px"
                        className="d-inline-block align-top"
                    />{' '}
                    <span>{brand}</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MainTopNavbar;
