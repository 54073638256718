import React from 'react';
import { Container } from 'react-bootstrap';
import MainTopNavbar from '../../navbars/main-top-navbar';
import MainFooter from '../../footers/main-footer';
import './style.css';

function HomePage() {
  return (
    <>
      <MainTopNavbar brand="Coimapor Ojusa" logo="/assets/images/logo.png" />
      <Container className="page-content d-flex justify-content-center align-items-center">
        <img src="/assets/images/under-construction.jpg" />
      </Container>
      <MainFooter 
        company="Coimapor Ojusa, S.L."
        logo="/assets/images/logo.png"
        description="Somos la boutique del marmolista. Suministramos maquinaria, utensilios, herramientas y productos químicos para el trabajo con la piedra, el mármol y el granito."
        address="Av. do Alcalde de Lavadores, 126, 36214 Vigo, Pontevedra"
        phone="986480022"
        email="coimapor@coimapor.com" />
    </>
  );
}

export default HomePage;
