import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaMapMarked } from 'react-icons/fa';
import { FiPhone } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import './style.css';

function MainFooter(props) {
    const { company, logo, description, address, phone, email } = props;

    return (
        <footer className="bg-dark pt-3 pt-md-5 text-light">
            <Container>
                <Row>
                    <Col xs={12} md={5}>
                        <div className="mb-4">
                            <div className="d-flex mb-2">
                                <div className="image-wrapper">
                                    <img src={logo} alt="logo" />
                                </div>
                                <span className="ms-2 fs-4">{company}</span>
                            </div>
                            <div className="fst-italic">
                                <p>{description}</p>
                            </div>
                        </div>
                        <div className="mb-2 d-flex">
                            <FaMapMarked className="mt-1" size={18} />
                            <p className="ms-2 mb-0">{address}</p>
                        </div>
                        <div className="mb-2 d-flex">
                            <FiPhone className="mt-1" size={18} />
                            <span className="ms-2">{phone}</span>
                        </div>
                        <div className="mb-2 d-flex">
                            <HiOutlineMail className="mt-1" size={18} />
                            <span className="ms-2">{email}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3 mt-md-5">
                    <Col className="text-center">
                        <small>coimapor-ojusa.com &copy; 2023</small>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default MainFooter;
